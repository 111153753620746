import React, { useState } from "react";
import { Table, Placeholder } from "react-bootstrap";
import TitleFilter from "./TitleFilter";

function JftMatchesTable(props) {
  const [matches, setMatches] = useState([]);

  if (!props.matches) {
    return (
      <Placeholder as={Table} animation="glow">
        <Placeholder xs={12} style={{ height: "15vh" }} />
      </Placeholder>
    );
  }

  return (
    <>
      <TitleFilter
        matches={props.matches}
        setMatches={setMatches}
        jobType="jft"
      />
      <Table striped bordered>
        <thead className="prophet-table-header">
          <tr>
            <th>Title</th>
            <th>Company</th>
            <th>Location</th>
            <th>Company Website</th>
            <th>Posted</th>
            <th>Url</th>
          </tr>
        </thead>
        <tbody>
          {matches.map((match, idx) => (
            <tr key={idx}>
              <td>{match?.title}</td>
              <td>
                <a href={match?.company_url}>{match?.company_name}</a>
              </td>
              <td>{match?.location}</td>
              <td>
                <a href={match?.company_website}>{match?.company_website}</a>
              </td>
              <td>{new Date(match?.posted).toDateString()}</td>
              <td>
                <a href={match?.posting_url}>Posting</a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default JftMatchesTable;
