import React, { useState } from "react";
import { Table, Placeholder } from "react-bootstrap";
import TitleFilter from "./TitleFilter";

function PfcwMatchesTable(props) {
  const [matches, setMatches] = useState([]);

  if (!props.matches) {
    return (
      <Placeholder as={Table} animation="glow">
        <Placeholder xs={12} style={{ height: "15vh" }} />
      </Placeholder>
    );
  }

  return (
    <>
      <TitleFilter
        matches={props.matches}
        setMatches={setMatches}
        jobType="pfcw"
      />
      <Table striped bordered>
        <thead className="prophet-table-header">
          <tr>
            <th>Match</th>
            <th>Title</th>
            <th>Company</th>
            <th>Company Website</th>
            <th>Hits</th>
          </tr>
        </thead>
        <tbody>
          {matches.map((match, idx) => (
            <tr key={idx}>
              <td>
                <a href={match?.profile_url} rel="external" target="_blank">
                  {match?.name}
                </a>
              </td>
              <td>{match?.occupation}</td>
              <td>
                <a
                  href={match?.company_profile_url}
                  rel="external"
                  target="_blank"
                >
                  {match?.company_name}
                </a>
              </td>
              <td>
                <a
                  href={"https://" + match?.url}
                  rel="external"
                  target="_blank"
                >
                  {match?.url}
                </a>
              </td>
              <td>
                <ul>
                  {(match?.hits_display ?? []).map((hit, idx) => {
                    return <li key={idx}>{hit}</li>;
                  })}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default PfcwMatchesTable;
