import { useEffect, useState } from "react";

import { PROPHET_API_URL } from "../../constants.js";
// import "./results.css";
import ReactGA from "react-ga";
import { useAuth0 } from "@auth0/auth0-react";
import { getAccessToken } from "../../utils/auth";
import HistoryTable from "./HistoryTable.js";
import ProgressWithTitle from "./../../components/ProgressWithTitle";

function History() {
  const [data, setData] = useState();
  const [startAt, setStartAt] = useState([]);
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const fetchData = async (url) => {
    try {
      const response = await fetch(url, {
        headers: {
          Authorization:
            "Bearer " +
            (await getAccessToken(
              getAccessTokenSilently,
              getAccessTokenWithPopup
            )),
        },
      });
      const json = await response.json();
      setData(json);
      return json;
    } catch (error) {
      console.log("error", error);
    }
  };

  const next = () => {
    setStartAt([...startAt, data.jobs[data.jobs.length - 1].created]);
  };

  const back = () => {
    setStartAt(startAt.slice(0, -1));
  };

  useEffect(() => {
    (async () => {
      const page = {
        page_title: `History`,
        page_location: window.location.href,
        page_path: `/history`,
      };
      ReactGA.set(page);
      ReactGA.pageview(page);
      let url = `${PROPHET_API_URL}/jobs?limit=15`;
      if (startAt.length) {
        url = url + `&startAt=${encodeURI(startAt[startAt.length - 1])}`;
      }

      fetchData(url);
    })();
  }, [startAt]);

  return (
    <main style={{ padding: "1rem 0" }}>
      <h2>Run History</h2>
      <HistoryTable
        jobs={data?.jobs}
        startAt={startAt}
        next={next}
        back={back}
      />
      <ProgressWithTitle
        percentDisplay={data?.jft?.remaining_percent_display}
        percent={data?.jft?.remaining_percent}
        title={`Job Search Pages Remaining: ${data?.jft?.quota}`}
      />
    </main>
  );
}

export default History;
