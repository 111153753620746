import React, { useState } from "react";
import { Table, Placeholder } from "react-bootstrap";
import TitleFilter from "./TitleFilter";

function PflMatchesTable(props) {
  const [matches, setMatches] = useState([]);

  if (!props.matches) {
    return (
      <Placeholder as={Table} animation="glow">
        <Placeholder xs={12} style={{ height: "15vh" }} />
      </Placeholder>
    );
  }

  return (
    <>
      <TitleFilter
        matches={props.matches}
        setMatches={setMatches}
        jobType="pfl"
      />
      <Table striped bordered>
        <thead className="prophet-table-header">
          <tr>
            <th>Match</th>
            <th>Title</th>
            <th>Hits</th>
          </tr>
        </thead>
        <tbody>
          {matches.map((match, idx) => {
            return (
              <tr key={idx}>
                <td>
                  <a href={match?.profile_url} rel="external" target="_blank">
                    {match?.name}
                  </a>
                </td>
                <td>{match?.occupation}</td>
                <td>
                  <ul>
                    {(match?.hits_display ?? []).map((hit) => {
                      return <li>{hit}</li>;
                    })}
                  </ul>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default PflMatchesTable;
