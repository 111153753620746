import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

export const Events = {
    TITLE_FILTER_CHANGE: 'title-filter-change'
}

/**
 * Singleton pattern of EventEmitter
 * Source: https://medium.com/@krzakmarek88/eventemitter-instead-of-lifting-state-up-f5f105054a5
 */
const Emitter = Object.freeze({
    on: (event, callback) => eventEmitter.on(event, callback),
    once: (event, callback) => eventEmitter.once(event, callback),
    off: (event, callback) => eventEmitter.off(event, callback),
    emit: (event, callback) => eventEmitter.emit(event, callback),
})

export default Emitter;
