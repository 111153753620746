import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PfcwResults from "./routes/results/pfcw_results";
import PflResults from "./routes/results/pfl_results";
import JftResults from "./routes/results/jft_results";
import Upload from "./routes/upload/upload";
import './styles.scss';
import { Auth0ProviderWithRedirectCallback } from "./components/Auth0ProviderWithRedirectCallback";
import ReactGA from "react-ga";

import { ProtectedRoute } from "./components/ProtectedRoute";
import History from "./routes/history/history";
import UploadJobs from "./routes/upload/jft/upload";

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize("G-VW920C7WLT", { send_page_views: false });
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
        domain="dev-6q17v-k7.us.auth0.com"
        clientId="9oFKq8XsXY55KgciwG3EV8S2uD45J56k"
        redirectUri={window.location.origin}
        audience={"https://dev-api.prophet-hiq.com"}
        cacheLocation={"localstorage"}
      >
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/pfcw/results/:id" element={<PfcwResults />} />
            <Route path="/pfl/results/:id" element={<PflResults />} />
            <Route path="/jft/results/:id" element={<JftResults />} />
            <Route
              path="/upload"
              element={<ProtectedRoute component={Upload} />}
            />
            <Route
              path="/jobs"
              element={<ProtectedRoute component={UploadJobs} />}
            />
            <Route
              path="/history"
              element={<ProtectedRoute component={History} />}
            />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Route>
        </Routes>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
