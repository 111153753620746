import { Placeholder, ProgressBar } from "react-bootstrap";

function ProgressWithTitle(props) {
  if (props.percentDisplay == undefined) {
    return (
      <div>
        <Placeholder as="h1" animation="glow">
          <Placeholder xs={4} />
        </Placeholder>
        <Placeholder as={ProgressBar} animation="glow">
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder as="h3" animation="glow">
          <Placeholder xs={4} />
        </Placeholder>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ flex: 1 }}>
          {props?.title} ({props.percentDisplay})
        </h2>
      </div>
      <ProgressBar striped variant="danger" now={props.percent} />
    </div>
  );
}

export default ProgressWithTitle;
