import { useState } from "react";
import {
  Container,
  Button,
  Form,
  Alert,
  Spinner,
  InputGroup,
  Badge,
  CloseButton,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PROPHET_API_URL } from "../../../constants.js";
import { flatten, arrayToLowercase } from "../../../utils/array.js";
import { useAuth0 } from "@auth0/auth0-react";
import titles from "../../../data/titles";
import "../upload";

function UploadJobs() {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [validated, _] = useState(false);

  const [statusText, setStatusText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [maxSpend, setMaxSpend] = useState();

  // Form Control Values
  const [name, setName] = useState();
  const [jobType, setJobType] = useState("jft"); // fixed for now
  const [location, setLocation] = useState();
  const [pages, setPages] = useState();
  const [matchTitle, setMatchTitle] = useState("");
  const [selectedTitleGroups, setSelectedTitleGroups] = useState([]);
  const [dev, setDev] = useState(false);

  // /**
  //  * Take in titles from textbox and dropdown.
  //  * Normalize text.
  //  * Figure out all the unique keywords.
  //  * Create an output string of all the unique keywords.
  //  */
  /**
   * The above was kinda weird. For now, just append them all together.
   */
  const createTargetTitle = () => {
    const customTitles = matchTitle
      .split(",")
      .map((x) => x.trim().toLowerCase())
      .filter((x) => x !== "");
    // const phrases = new Set(
    //   arrayToLowercase([...customTitles, ...flatten(selectedTitleGroups)])
    //     .join(" ")
    //     .replace(",", "")
    //     .split(" ")
    // );
    // return Array.from(phrases).join(" ");
    return arrayToLowercase([
      ...customTitles,
      ...selectedTitleGroups.map((x) => x[0]),
    ]).join(" ");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log("Submit!");
    const title = createTargetTitle();

    if (!location) {
      setErrorText("Please enter a location");
      setLoading(false);
      return;
    }
    if (!title) {
      setErrorText("Please enter or select a title");
      setLoading(false);
      return;
    }
    if (!pages || pages < 0 || Math.floor(pages) != pages) {
      setErrorText("Please enter a valid number of pages");
      setLoading(false);
      return;
    }

    try {
      let jobId = await upload({
        title,
        location,
        pages,
        name,
        dev,
        maxSpend,
      });
      await send_process_request(jobId, dev);
      navigate(`/${jobType}/results/${jobId}`);
    } catch (e) {
      setStatusText("");
      setErrorText(e);
    }

    setLoading(false);
  };

  const upload = (obj) => {
    return new Promise(async (resolve, reject) => {
      console.log("Sending request!");
      let xhr = new XMLHttpRequest();

      xhr.onreadystatechange = function () {
        // Handle response
        if (xhr.readyState === 4) {
          console.log(xhr.status, xhr.response);
          if (xhr.status === 201) {
            resolve(xhr.response);
          } else {
            reject(
              "Error: Got unexpected status return " +
                xhr.status +
                ": " +
                xhr.response
            );
          }
        }
      };

      var url = `create_${jobType}_job`;
      xhr.open("POST", `${PROPHET_API_URL}/${url}`, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + (await getAccessTokenSilently())
      );
      let bodyStr = JSON.stringify(obj);
      console.log("Body str", bodyStr);
      xhr.send(bodyStr);
    });
  };

  const send_process_request = (job_id, dev) => {
    // TODO Hella dupe code with upload
    return new Promise(async (resolve, reject) => {
      console.log("Sending request!");
      let xhr = new XMLHttpRequest();

      xhr.onreadystatechange = function () {
        // Handle response
        if (xhr.readyState === 4) {
          console.log(xhr.status, xhr.response);
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(
              "Error: Got unexpected status return for process " +
                xhr.status +
                ": " +
                xhr.response
            );
          }
        }
      };

      var url = `process_${jobType}_job`;
      xhr.open("POST", `${PROPHET_API_URL}/${url}`, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + (await getAccessTokenSilently())
      );
      let bodyStr = JSON.stringify({
        job_id,
        dev,
      });
      xhr.send(bodyStr);
    });
  };

  const removeTitleGroup = (remove) => {
    setSelectedTitleGroups(
      selectedTitleGroups.filter((group) => group !== remove)
    );
  };

  return (
    <main>
      <Container>
        <h1>Search for jobs</h1>

        {errorText !== "" && (
          <Alert style={{ marginTop: "15px" }} variant="danger">
            {errorText}
          </Alert>
        )}

        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          style={{ marginTop: "20px" }}
        >
          {/* Name */}
          <Form.Group className="mb-3" controlId="maxEmployeeMonths">
            <Form.Label>Job Name (optional)</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => {
                setName(e.target.value);
              }}
            ></Form.Control>
          </Form.Group>

          {/* Max Spend */}
          <Form.Group className="mb-3" controlId="maxEmployeeMonths">
            <Form.Label>Max Spend (USD)</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => {
                setMaxSpend(parseFloat(e.target.value));
              }}
            ></Form.Control>
            <Form.Text>Maximum spend allowed by the job in USD</Form.Text>
          </Form.Group>

          {/* Match Title */}
          <Form.Group className="mb-3" controlId="matchTitle">
            <Form.Label>Match Titles</Form.Label>
            <InputGroup className="mb-1">
              <Form.Select
                value={-1}
                onChange={(e) => {
                  e.preventDefault();
                  setSelectedTitleGroups([
                    ...selectedTitleGroups,
                    titles[e.target.value],
                  ]);
                }}
              >
                <option disabled value={-1}>
                  -- Click on a title to add --
                </option>
                {titles.map((group, idx) => (
                  <option
                    disabled={flatten(selectedTitleGroups).includes(group[0])}
                    value={idx}
                    key={idx}
                  >
                    {group[0]}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
            {selectedTitleGroups.map((group, idx) => (
              <Badge pill className="m-1" bg="secondary" key={idx}>
                {group[0] || "No Title"}
                <CloseButton
                  style={{ verticalAlign: "middle", marginLeft: "5px" }}
                  onClick={() => removeTitleGroup(group)}
                />
              </Badge>
            ))}
            <Form.Control
              type="text"
              onChange={(e) => setMatchTitle(e.target.value)}
            ></Form.Control>
            <Form.Text>
              Employee titles to match for, case insensitive. Do not add commas.
            </Form.Text>
          </Form.Group>

          {/* Location */}
          <Form.Group className="mb-3" controlId="location">
            <Form.Label>Search location</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setLocation(e.target.value)}
            ></Form.Control>
            <Form.Text>
              Location to search in. Could be a state or zip (Maybe city?)
            </Form.Text>
          </Form.Group>

          {/* Max Exmployee Count */}
          <Form.Group className="mb-3" controlId="maxEmployeeCount">
            <Form.Label>Pages</Form.Label>
            <Form.Control
              type="number"
              step="1"
              onChange={(e) => {
                setPages(e.target.value);
              }}
            ></Form.Control>
            <Form.Text>
              Number of job pages to search through (over 40 may result in high
              duplicates)
            </Form.Text>
          </Form.Group>

          {/* Run Dev */}
          <Form.Group className="mb-3" controlId="runDev">
            <Form.Check
              type="checkbox"
              label="Run on Development Servers"
              onChange={(e) => setDev(!dev)}
            ></Form.Check>
            <Form.Text>
              Temporary option to run search on dev servers instead of prod
              servers. Do not use unless you know what you are doing.
            </Form.Text>
          </Form.Group>

          {/* Submit */}
          <Button type="submit" variant="danger">
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
                Loading...
              </>
            ) : (
              "Submit"
            )}
          </Button>
          <p style={{ margin: "0px", marginLeft: "10px" }}>{statusText}</p>
          <Spinner></Spinner>
        </Form>
      </Container>
    </main>
  );
}

export default UploadJobs;
