import { Outlet, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import LogInOutButton from "./components/LogInOutButton";

export default function App() {
  return (
    <div>

      {/* Nav Bar */}
      <Container fluid>
        <div className="w-100" style={{ "display": "flex", "alignItems": "spaceBetween" }}>
          <Nav className="align-items-center">
            <Nav.Item>
              <Link to="/">
                <img
                  src="https://prophet-hiq-public.s3.us-east-1.amazonaws.com/prophet.png" style={{ width: "150px" }} alt="prophet logo" />
              </Link>
            </Nav.Item>
          </Nav>
          <Nav className="align-items-center">
            <Nav.Item>
              <LogInOutButton />
            </Nav.Item>
          </Nav>
        </div>

      </Container>

      {/* Page Content */}
      <Container>
        <Outlet />
      </Container>
    </div>
  );
}
