import { useEffect, useState } from "react";

import PflMatchesTable from "./PflMatchesTable";
import ProgressHeader from "../../components/ProgressHeader";
import StatsTable from "./StatsTable";
import DefinitionTable from "./DefinitionTable";
import StatusTable from "./StatusTable";
import { useParams } from "react-router-dom";
import { PROPHET_API_URL } from "../../constants.js";
import "./results.css";
import ReactGA from "react-ga";

function PflResults() {
  const { id } = useParams();
  const [data, setData] = useState("");
  const REFRESH_IN_STATES = ["PENDING", "PROCESSING"];

  useEffect(() => {
    (async () => {
      const page = {
        page_title: `PFL ${id}`,
        page_location: window.location.href,
        page_path: `/pfl/results/${id}`,
      };
      ReactGA.set(page);
      ReactGA.pageview(page);
      const url = `${PROPHET_API_URL}/get_pfl_job_status?job_id=${id}`;

      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            headers: {
              // NOTE Disabled due to client request
              // 'Authorization': 'Bearer ' + (await getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup))
            },
          });
          const json = await response.json();
          console.log(json);
          setData(json);
          return json;
        } catch (error) {
          console.log("error", error);
        }
      };

      const localdata = await fetchData();
      if (REFRESH_IN_STATES.includes(localdata?.status)) {
        const int = setInterval(async () => {
          const localdata = await fetchData();
          if (!REFRESH_IN_STATES.includes(localdata?.status))
            clearInterval(int);
        }, 5000);
      }
    })();
  }, []);

  return (
    <main style={{ padding: "1rem 0" }}>
      <ProgressHeader
        percentComplete={data?.urls?.percent_complete}
        percentCompleteDisplay={data?.urls?.percent_complete_display}
        status={data?.status}
        statusReason={data?.status_reason}
        showButton={(data?.matches || []).length > 0}
        jobId={data?.job_id}
        name={data?.name}
        jobType={"pfl"}
      />

      <hr />

      <h2>Stats</h2>
      <StatsTable
        successes={data?.match_count}
        creditsConsumed={data?.cost_consumed}
        maxSpend={data?.max_spend}
        previousNetsuiteExperiences={data?.previous_netsuite_experiences}
      ></StatsTable>

      <hr />

      <h2>Matches</h2>
      <PflMatchesTable
        terms={data?.definition?.terms}
        matches={data?.matches}
      ></PflMatchesTable>

      <hr />

      <h2>Status Breakdown</h2>
      <StatusTable
        statuses={data?.urls?.statuses}
        total={data?.urls?.total}
      ></StatusTable>

      <hr />

      <h2>Job Definition</h2>
      <DefinitionTable
        time_created={data?.time_created}
        role_search={data?.role_search}
        looking_for={data?.looking_for}
      ></DefinitionTable>
    </main>
  );
}

export default PflResults;
