import React from "react";
import { Placeholder, Table, Button } from "react-bootstrap";

function HistoryTable(props) {
  if (props.jobs == undefined) {
    return (
      <Placeholder as={Table} animation="glow">
        <Placeholder xs={12} style={{ height: "15vh" }} />
      </Placeholder>
    );
  }

  let backButton = <div></div>;
  if (props.startAt.length) {
    backButton = (
      <Button variant="primary" onClick={props.back}>
        Last Page
      </Button>
    );
  }

  return (
    <>
      <Table striped bordered>
        <thead className="prophet-table-header">
          <tr>
            <th>Job Id</th>
            <th>Type</th>
            <th>Status</th>
            <th>Spend</th>
            <th>Max Spend</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {props.jobs.map((job, idx) => (
            <tr key={idx}>
              <td>
                <a href={`/${job?.type}/results/${job?.id}`}>
                  {job?.name ?? job?.id}
                </a>
              </td>
              <td>{job?.type}</td>
              <td>{job?.status}</td>
              <td>{job?.spend}</td>
              <td>{job?.max_spend}</td>
              <td>{new Date(job?.created).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {backButton}
        <Button
          className="primary"
          onClick={props.next}
        >
          Next Page
        </Button>
      </div>
    </>
  );
}

export default HistoryTable;
