import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return <div></div>
    // return <a href="#" onClick={() => loginWithRedirect()}>Log In</a>
};

export default LoginButton;
