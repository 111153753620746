import React, { useEffect, useState } from "react";
import { Form, Badge, CloseButton } from "react-bootstrap";
import titles from "../../data/titles";
import Emitter, { Events } from "../../eventEmitter";
import { flatten, arrayToLowercase } from "../../utils/array";

function TitleFilter({ matches, setMatches, jobType }) {
  // Matrix of filter groups
  const [filters, setFilters] = useState([]);

  // Matrix of filters currently applied to matches
  const [appliedFilters, setAppliedFilters] = useState([]);

  // appliedFilters matrix with 1 depth, all lowercase for comparison
  const [flatAppliedFilters, setFlatAppliedFilters] = useState([]);

  // Get array of titles from match's hits
  const getTitles = (match) => {
    if (jobType === "jft") {
      return [match.title];
    }

    const titles = match.hits_display
      ?.filter((hit) =>
        // Filter by hits including a title
        hit.includes("_TITLE")
      )
      .map((titleHit) =>
        // For each hit, get value of title and sanitize
        titleHit.split("includes")[1].trim().replace(/'/g, "")
      );

    return titles.length > 0 ? titles : ["Champion"];
  };

  // If title belongs to group, return the group, otherwise return the title by itself
  const normalizeTitle = (title) => {
    for (const group of titles) {
      if (arrayToLowercase(group).includes(title)) {
        return `${group}`;
      }
    }

    return title;
  };

  /** Component Did Mount
   * TODO: Figure out how to refactor this for performance
   *       The second loop is on arrays that are usually 1-2 length, and the third loop is on a
   *       constant space matrix so its not *as* bad as it looks, but still could be looked into.
   */
  useEffect(() => {
    if (matches) {
      // Use a set of strings to avoid duplicates
      let allTitles = new Set();
      for (const match of matches) {
        for (const title of getTitles(match)) {
          allTitles.add(normalizeTitle(title));
        }
      }
      // Transform Set into matrix
      setFilters(
        Array.from(allTitles)
          .map((title) => title.split(","))
          .sort()
      );
    }
  }, []);

  // Flatten and lowercase filters matrix for comparisons
  useEffect(
    () => setFlatAppliedFilters(arrayToLowercase(flatten(appliedFilters))),
    [appliedFilters]
  );

  // Filter matches
  useEffect(() => {
    if (matches && flatAppliedFilters.length > 0) {
      // Filter matches by ones who have at least one title hit applied as a filter
      const filtered = matches.filter((match) =>
        arrayToLowercase(getTitles(match)).some((title) =>
          flatAppliedFilters.includes(title)
        )
      );
      setMatches(filtered);
    } else {
      setMatches(matches);
    }
    Emitter.emit(Events.TITLE_FILTER_CHANGE, flatAppliedFilters);
  }, [matches, flatAppliedFilters]);

  const removeAppliedFilter = (remove) => {
    setAppliedFilters(appliedFilters.filter((group) => group !== remove));
  };

  return (
    <Form.Group className="mb-3">
      <Form.Label>Title Filter</Form.Label>
      <Form.Select
        className="mb-1"
        value={-1}
        onChange={(e) => {
          e.preventDefault();
          setAppliedFilters([...appliedFilters, filters[e.target.value]]);
        }}
      >
        <option disabled value={-1}>
          -- Click on a title to add --
        </option>
        {filters.map((filter, idx) => (
          <option
            disabled={flatten(appliedFilters).includes(filter[0])}
            value={idx}
            key={idx}
          >
            {filter[0] || "Champions"}
          </option>
        ))}
      </Form.Select>
      {appliedFilters.map((filter, idx) => (
        <Badge pill className="m-1" bg="secondary" key={idx}>
          {filter[0] || "No Title"}
          {/* For some reason putting ml-1 class here is doing nothing for me */}
          <CloseButton
            style={{ verticalAlign: "middle", marginLeft: "5px" }}
            onClick={() => removeAppliedFilter(filter)}
          />
        </Badge>
      ))}
    </Form.Group>
  );
}

export default TitleFilter;
