import React from "react";
import { Table, Placeholder } from 'react-bootstrap';

function DefinitionTable(props) {
    if (props.time_created == undefined) {
        return <Placeholder as={Table} animation="glow">
            <Placeholder xs={12} style={{ height: "15vh" }} />
        </Placeholder>;
    }

    return <Table striped bordered>
        <thead className="prophet-table-header">
            <tr>
                <th>Field</th>
                <th>Value(s)</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Time Created</td>
                <td>{props.time_created}</td>
            </tr>
            <tr>
                <td>Role Search</td>
                <td>{props.role_search ?? '-'}</td>
            </tr>
            <tr>
                <td>Looking For</td>
                <td style={{ "whiteSpace": "pre-wrap" }}>{props.looking_for ?? '-'}</td>
            </tr>
        </tbody>
    </Table>
}

export default DefinitionTable;
