import React, { useEffect, useState } from "react";
import { Placeholder, ProgressBar, Button } from "react-bootstrap";
import { PROPHET_API_URL } from "../constants.js";
import { useAuth0 } from "@auth0/auth0-react";
import { getAccessToken } from "../utils/auth.js";
import Emitter, { Events } from "../eventEmitter";

const cancel = (jobId, dev, getAccessToken) => {
  return new Promise(async (resolve, reject) => {
    let xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
      // Handle response
      if (xhr.readyState === 4) {
        console.log(xhr.status, xhr.response);
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(
            "Error: Got unexpected status return when cancelling " +
              xhr.status +
              ": " +
              xhr.response
          );
        }
      }
    };

    var url = `cancel_job`;
    xhr.open("POST", `${PROPHET_API_URL}/${url}`, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + (await getAccessToken()));
    let bodyStr = JSON.stringify({
      job_id: jobId,
      dev: dev || false,
    });
    xhr.send(bodyStr);
  });
};

const reprocess = (jobId, dev, getAccessToken, jobType) => {
  return new Promise(async (resolve, reject) => {
    let xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
      // Handle response
      if (xhr.readyState === 4) {
        console.log(xhr.status, xhr.response);
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(
            "Error: Got unexpected status return when cancelling " +
              xhr.status +
              ": " +
              xhr.response
          );
        }
      }
    };

    var url = `reprocess_${jobType}_job`;
    xhr.open("POST", `${PROPHET_API_URL}/${url}`, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + (await getAccessToken()));
    let bodyStr = JSON.stringify({
      job_id: jobId,
      dev: dev || false,
      useEfficientClient: false,
    });
    xhr.send(bodyStr);
  });
};

function ProgressHeader(props) {
  const { getAccessTokenWithPopup, getAccessTokenSilently } = useAuth0();

  const [titleFilters, setTitleFilters] = useState([]);

  useEffect(() => {
    Emitter.on(Events.TITLE_FILTER_CHANGE, (appliedFilters) =>
      setTitleFilters(appliedFilters)
    );
    return () => Emitter.off(Events.TITLE_FILTER_CHANGE);
  });

  if (
    props.percentComplete == undefined ||
    props.percentCompleteDisplay == undefined
  ) {
    return (
      <div>
        <Placeholder as="h1" animation="glow">
          <Placeholder xs={4} />
        </Placeholder>
        <Placeholder as={ProgressBar} animation="glow">
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder as="h3" animation="glow">
          <Placeholder xs={4} />
        </Placeholder>
      </div>
    );
  }

  const statusMessaage = props?.statusReason ? `(${props.statusReason})` : "";
  const canCancel = props.status === "PENDING" || props.status === "PROCESSING";
  const canReprocess = props.status === "CANCELLED";

  return (
    <div>
      <h1>{props?.name ?? props?.jobId}</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ flex: 1 }}>Job {props.percentCompleteDisplay} Complete</h2>
        <Button
          variant="primary"
          disabled={!canCancel}
          style={{ marginRight: "1em" }}
          onClick={async () => {
            if (canCancel) {
              await cancel(props.jobId, false, () =>
                getAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
              ); // TODO Make dev work
              window.location.reload();
            }
          }}
        >
          Cancel Job
        </Button>
        <Button
          variant="primary"
          disabled={!canReprocess}
          style={{ marginRight: "1em" }}
          onClick={async () => {
            if (canReprocess) {
              await reprocess(
                props.jobId,
                false,
                () =>
                  getAccessToken(
                    getAccessTokenSilently,
                    getAccessTokenWithPopup
                  ),
                props.jobType
              ); // TODO Make dev work
              window.location.reload();
            }
          }}
        >
          Reprocess Job
        </Button>
        <Button
          variant="primary"
          disabled={!props.showButton}
          onClick={async () => {
            if (props.showButton) {
              fetch(
                `${PROPHET_API_URL}/get_${props.jobType}_job_csv?job_id=${props.jobId}&title_filters=${titleFilters}`,
                {
                  headers: {
                    Authorization:
                      "Bearer " +
                      (await getAccessToken(
                        getAccessTokenSilently,
                        getAccessTokenWithPopup
                      )),
                  },
                }
              )
                .then((res) => res.blob())
                .then((blob) => {
                  var file = window.URL.createObjectURL(blob);
                  window.location.assign(file);
                });
            }
          }}
        >
          Download CSV
        </Button>
      </div>
      <ProgressBar striped variant="danger" now={props.percentComplete} />
      <h3>
        Status: {props.status} {statusMessaage}
      </h3>
    </div>
  );
}

export default ProgressHeader;
