import React from "react";
import { Table, Placeholder } from 'react-bootstrap';

function StatusTable(props) {
    if (props.statuses == undefined || props.total == undefined) {
        return <Placeholder as={Table} animation="glow">
            <Placeholder xs={12} style={{ height: "15vh" }} />
        </Placeholder>;
    }

    return <Table striped bordered>
        <thead className="prophet-table-header">
            <tr>
                <th>Status</th>
                <th>Count</th>
                <th>Percentage</th>
            </tr>
        </thead>
        <tbody>
            {
                Object.keys(props.statuses).map((status) =>
                    <tr key={status}>
                        <td>{status}</td>
                        <td>{props.statuses[status]["count"]}</td>
                        <td>{props.statuses[status]["percent_dispaly"]}</td>
                    </tr>
                )
            }
            <tr>
                <td style={{ fontWeight: "bold" }}>Total</td>
                <td style={{ fontWeight: "bold" }}>{props.total}</td>
                <td style={{ fontWeight: "bold" }}>100.00%</td>
            </tr>
        </tbody>
    </Table>
}

export default StatusTable;
