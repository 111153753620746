import { useState } from "react";
import {
  Container,
  Button,
  Form,
  Alert,
  Spinner,
  InputGroup,
  Badge,
  CloseButton,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PROPHET_BASE_URL, PROPHET_API_URL } from "../../constants.js";
import { flatten, arrayToLowercase } from "../../utils/array.js";
import { useAuth0 } from "@auth0/auth0-react";
import titles from "../../data/titles";
import "./upload.css";

function Upload() {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [validated, _] = useState(false);
  const [rawFile, setRawFile] = useState();

  const [statusText, setStatusText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [previewLink, setPreviewLink] = useState("");
  const [loading, setLoading] = useState(false);

  // Form Control Values
  const [name, setName] = useState();
  const [jobType, setJobType] = useState("pfcw");
  const [maxEmployeeCount, setMaxEmployeeCount] = useState(0);
  const [matchTitle, setMatchTitle] = useState("");
  const [selectedTitleGroups, setSelectedTitleGroups] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [allMatches, setAllMatches] = useState(false);
  const [months, setMonths] = useState(0);
  const [dev, setDev] = useState(false);
  const [roleSearch, setRoleSearch] = useState(false);
  const [maxSpend, setMaxSpend] = useState();
  const [useEfficientClient, setUseEfficientClient] = useState(false);

  const joinMatchTitle = () => {
    const customTitles = matchTitle
      .split(",")
      .map((x) => x.trim().toLowerCase())
      .filter((x) => x !== "");
    return [...customTitles, ...arrayToLowercase(flatten(selectedTitleGroups))];
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!rawFile) {
      setErrorText("Error: No file uploaded.");
      return;
    }

    setLoading(true);
    console.log("Submit!");

    try {
      let jobId = await uploadBatched(
        {
          max_employee_count: maxEmployeeCount, //
          match_title: joinMatchTitle(), //
          keyword: keyword, //
          all_matches: allMatches,
          months: months,
          dev: dev,
          role_search: roleSearch,
          max_spend: maxSpend,
          name: name,
        },
        dev
      );
      await send_process_request(jobId, dev, useEfficientClient);
      navigate(`/${jobType}/results/${jobId}`);
    } catch (e) {
      setStatusText("");
      setErrorText(e);
    }

    setLoading(false);
  };

  const handleDrop = (event) => {
    console.log("File(s) dropped");
    event.preventDefault();

    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (event.dataTransfer.items[i].kind === "file") {
          let file = event.dataTransfer.items[i].getAsFile();
          console.log("... file[" + i + "].name = " + file.name);

          const reader = new FileReader();
          reader.onload = function (event) {
            setRawFile(event.target.result);
          };
          reader.readAsText(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        console.log(
          "... file[" + i + "].name = " + event.dataTransfer.files[i].name
        );
      }
    }
  };

  const handleDragOver = (event) => {
    console.log("File(s) in drop zone");
    event.preventDefault();
  };

  const upload = (obj) => {
    return new Promise(async (resolve, reject) => {
      console.log("Sending request!");
      let xhr = new XMLHttpRequest();

      xhr.onreadystatechange = function () {
        // Handle response
        if (xhr.readyState === 4) {
          console.log(xhr.status, xhr.response);
          if (xhr.status === 201) {
            resolve(xhr.response);
          } else {
            reject(
              "Error: Got unexpected status return " +
                xhr.status +
                ": " +
                xhr.response
            );
          }
        }
      };

      var url = `create_${jobType}_job`;
      xhr.open("POST", `${PROPHET_API_URL}/${url}`, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + (await getAccessTokenSilently())
      );
      let bodyStr = JSON.stringify(obj);
      console.log("rawFile", rawFile, typeof rawFile);
      console.log("Body str", bodyStr);
      xhr.send(bodyStr);
    });
  };

  const send_process_request = (job_id, dev, useEfficientClient) => {
    // TODO Hella dupe code with upload
    return new Promise(async (resolve, reject) => {
      console.log("Sending request!");
      let xhr = new XMLHttpRequest();

      xhr.onreadystatechange = function () {
        // Handle response
        if (xhr.readyState === 4) {
          console.log(xhr.status, xhr.response);
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(
              "Error: Got unexpected status return for process " +
                xhr.status +
                ": " +
                xhr.response
            );
          }
        }
      };

      var url = `process_${jobType}_job`;
      xhr.open("POST", `${PROPHET_API_URL}/${url}`, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + (await getAccessTokenSilently())
      );
      let bodyStr = JSON.stringify({
        job_id,
        dev,
        useEfficientClient,
      });
      xhr.send(bodyStr);
    });
  };

  const uploadBatched = async (request, dev) => {
    let _lines = new Set();
    rawFile
      .split("\r\n")
      .map((x) => x.trim())
      .reduce((s, e) => s.add(e), _lines);
    let lines = Array.from(_lines);

    const chunkSize = 400;
    const concurrentChunks = 10;

    setStatusText(0 + "/" + lines.length);
    // Upload first chunkSize amount
    const req = {
      file_raw: lines.slice(0, chunkSize).join("\r\n"),
      dev: dev,
      request: request,
    };
    console.log(req);
    const jobId = await upload(req).catch((e) => {
      throw e;
    });

    setPreviewLink(`${PROPHET_BASE_URL}/${jobType}/results/${jobId}`);

    let chunks = [];
    for (let i = chunkSize; i < lines.length; i += chunkSize) {
      const chunk = lines.slice(i, i + chunkSize).join("\r\n");
      chunks.push(chunk);
    }

    const initChunks = chunks.length + 1;
    setStatusText(
      (initChunks - chunks.length) * chunkSize + "/" + lines.length
    );
    while (chunks.length) {
      await Promise.all(
        chunks.splice(0, concurrentChunks).map((chunk) => {
          return upload({
            file_raw: chunk,
            dev: dev,
            existing_id: jobId,
          }).catch((e) => {
            throw e;
          });
        })
      );
      setStatusText(
        (initChunks - chunks.length) * chunkSize + "/" + lines.length
      );
    }

    setStatusText("Done!");
    return jobId;
  };

  const removeTitleGroup = (remove) => {
    setSelectedTitleGroups(
      selectedTitleGroups.filter((group) => group !== remove)
    );
  };

  return (
    <main>
      <Container>
        <h1>Create a Prophet Job</h1>

        <Container
          className="upload-dropZone"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          {rawFile ? (
            <h4 style={{ color: "green" }}>Successfully Uploaded CSV</h4>
          ) : (
            <h4>Drag Input CSV Here</h4>
          )}
        </Container>
        {errorText !== "" && (
          <Alert style={{ marginTop: "15px" }} variant="danger">
            {errorText}
          </Alert>
        )}

        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          style={{ marginTop: "20px" }}
        >
          {/* Input Type */}
          <Form.Group className="mb-3" controlId="inputCsvType">
            <Form.Label>Input File Type</Form.Label>
            <Form.Check
              type={"radio"}
              label={`Company Website List`}
              id={`company-website-list`}
              name={"input-file-type"}
              checked={jobType === "pfcw"}
              onClick={() => setJobType("pfcw")}
            />

            <Form.Check
              type={"radio"}
              label={`User Profile List`}
              id={`user-profile-list`}
              name={"input-file-type"}
              checked={jobType === "pfl"}
              onClick={() => setJobType("pfl")}
            />
            <Form.Text>
              What kind of input CSV is being provided. Either a list of company
              websites (not linkedin profiles), or a list of user linkedin
              profiles.
            </Form.Text>
          </Form.Group>

          {/* Name */}
          <Form.Group className="mb-3" controlId="maxEmployeeMonths">
            <Form.Label>Job Name (optional)</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => {
                setName(e.target.value);
              }}
            ></Form.Control>
          </Form.Group>

          {/* Max Spend */}
          <Form.Group className="mb-3" controlId="maxEmployeeMonths">
            <Form.Label>Max Spend (USD)</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => {
                setMaxSpend(parseFloat(e.target.value));
              }}
            ></Form.Control>
            <Form.Text>Maximum spend allowed by the job in USD</Form.Text>
          </Form.Group>

          {/* Max Exmployee Count */}
          <Form.Group className="mb-3" controlId="maxEmployeeCount">
            <Form.Label>Max Employee Count</Form.Label>
            <Form.Control
              disabled={jobType != "pfcw"}
              type="number"
              onChange={(e) => {
                setMaxEmployeeCount(e.target.value);
              }}
            ></Form.Control>
            <Form.Text>
              Maximum number of employees for a company to be valid. Leave blank
              to allow any number.
            </Form.Text>
          </Form.Group>

          {/* Max Employee Months */}
          <Form.Group className="mb-3" controlId="maxEmployeeMonths">
            <Form.Label>Employee Months at Company</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => {
                setMonths(e.target.value);
              }}
            ></Form.Control>
            <Form.Text>
              Maximum number of months that an employee has been at the given
              company. Leave blank to allow any number.
            </Form.Text>
          </Form.Group>

          {/* Match Title */}
          <Form.Group className="mb-3" controlId="matchTitle">
            <Form.Label>Match Titles</Form.Label>
            <InputGroup className="mb-1">
              <Form.Select
                value={-1}
                onChange={(e) => {
                  e.preventDefault();
                  setSelectedTitleGroups([
                    ...selectedTitleGroups,
                    titles[e.target.value],
                  ]);
                }}
              >
                <option disabled value={-1}>
                  -- Click on a title to add --
                </option>
                {titles.map((group, idx) => (
                  <option
                    disabled={flatten(selectedTitleGroups).includes(group[0])}
                    value={idx}
                    key={idx}
                  >
                    {group[0]}
                  </option>
                ))}
              </Form.Select>
              <Button 
                variant="outline-primary"
                onClick={() => setSelectedTitleGroups(titles)}
              >
                Add All
              </Button>
            </InputGroup>
            {selectedTitleGroups.map((group, idx) => (
              <Badge pill className="m-1" bg="secondary" key={idx}>
                {group[0] || "No Title"}
                <CloseButton
                  style={{ verticalAlign: "middle", marginLeft: "5px" }}
                  onClick={() => removeTitleGroup(group)}
                />
              </Badge>
            ))}
            <Form.Control
              type="text"
              onChange={(e) => setMatchTitle(e.target.value)}
            ></Form.Control>
            <Form.Text>
              Employee titles to match for, case insensitive. Separate by comma.
            </Form.Text>
          </Form.Group>

          {/* Keywords */}
          <Form.Group className="mb-3" controlId="matchKeywords">
            <Form.Label>Match Keywords</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) =>
                setKeyword(
                  e.target.value
                    .split(",")
                    .map((x) => x.trim().toLowerCase())
                    .filter((x) => x !== "")
                )
              }
            ></Form.Control>
            <Form.Text>
              Keywords to find in employee profiles, case insensitive. Separate
              by comma. Note, when role search is selected, this has no effect.
            </Form.Text>
          </Form.Group>

          {/* All Matches */}
          <Form.Group className="mb-3" controlId="allMatches">
            <Form.Check
              type="checkbox"
              label="Return All Matches"
              onChange={() => setAllMatches(!allMatches)}
            ></Form.Check>
            <Form.Text>
              If selected, finds all matches at a given company. Otherwise,
              stops looking at a company after the first match.
            </Form.Text>
          </Form.Group>

          {/* Role Search */}
          <Form.Group className="mb-3" controlId="roleSearch">
            <Form.Check
              type="checkbox"
              label="Role Search"
              onChange={() => setRoleSearch(!roleSearch)}
            ></Form.Check>
            <Form.Text>
              When selected, uses role search cost optimization strategy. Will
              only pull matches with a title listed above.
            </Form.Text>
          </Form.Group>

          {/* Use Efficient Client */}
          <Form.Group className="mb-3" controlId="useEfficientClient">
            <Form.Check
              type="checkbox"
              label="Use Efficient Client"
              onChange={(e) => setUseEfficientClient(!useEfficientClient)}
              checked={useEfficientClient}
            ></Form.Check>
            <Form.Text>
              When checked, will use the hybrid data fetching mode to attempt to
              save money.
            </Form.Text>
          </Form.Group>

          {/* Run Dev */}
          <Form.Group className="mb-3" controlId="runDev">
            <Form.Check
              type="checkbox"
              label="Run on Development Servers"
              onChange={(e) => setDev(!dev)}
            ></Form.Check>
            <Form.Text>
              Temporary option to run search on dev servers instead of prod
              servers. Do not use unless you know what you are doing.
            </Form.Text>
          </Form.Group>

          {/* Submit */}
          <Button type="submit" variant="primary">
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
                Loading...
              </>
            ) : (
              "Submit"
            )}
          </Button>
          <p style={{ margin: "0px", marginLeft: "10px" }}>{statusText}</p>
          <a
            href={previewLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: "10px" }}
          >
            {previewLink !== "" ? "Preview" : ""}
          </a>
          <Spinner></Spinner>
        </Form>
      </Container>
    </main>
  );
}

export default Upload;
