import React from "react";
import { Placeholder, Table } from "react-bootstrap";

function StatusTable(props) {
  if (props.successes == undefined) {
    return (
      <Placeholder as={Table} animation="glow">
        <Placeholder xs={12} style={{ height: "15vh" }} />
      </Placeholder>
    );
  }

  let pne = <></>;
  if (props?.previousNetsuiteExperiences) {
    pne = (
      <tr>
        <td>Previous Netsuite Experiences</td>
        <td>{props.previousNetsuiteExperiences}</td>
      </tr>
    );
  }

  return (
    <Table striped bordered>
      <thead className="prophet-table-header">
        <tr>
          <th>Stat</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Matches Found</td>
          <td>{props.successes}</td>
        </tr>
        {/* <tr>
                <td>Spend (USD)</td>
                <td>${(props.creditsConsumed).toFixed(2)}</td>
            </tr>
            <tr>
                <td>Max Spend</td>
                <td>{props.maxSpend}</td>
            </tr>
            <tr>
                <td>Spend/Match (USD)</td>
                <td>${(props.creditsConsumed / props.successes).toFixed(2)}</td>
            </tr> */}
        {pne}
      </tbody>
    </Table>
  );
}

export default StatusTable;
