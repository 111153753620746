/**
 * Match Titles Matrix
 * 0th index is primary/display name.
 */
export default [
    ["Chief Executive Officer", "CEO"],
    ["Chief Financial Officer", "CFO"],
    ["Chief Operating Officer", "COO"],
    ["Chief Information Officer", "CIO"],
    ["Chief Marketing Officer", "CMO"],
    ["Chief Technology Officer", "CTO"],
    ["Chief Revenue Officer", "CRO"],
    ["Chief Compliance Officer", "CCO"],
    ["Chief Human Resources Manager", "CHRM"],
    ["Chief Security Officer", "CSO"],
    ["Chief Green Officer", "CGO"],
    ["Chief Analytics Officer", "CAO"],
    ["Chief Marketing Officer", "CMO"],
    ["Chief Data Officer", "CDO"],
    ["Founder"],
    ["Co-Founder", "Co Founder"],
    ["Owner"],
    ["Co-Owner", "Co Owner"],
    ["Accountant", "Senior Accountant", "Bookkeeper", "Accounts Payable Specialist", "Accounting Assistant", "Accounting Clerk", "Accounting Associate", "Accounting Specialist"],
    ["Accounting Manager", "Senior Accounting Manager", "Accounting Supervisor"],
    ["Financial Controller", "Controller", "Financial Director", "Finance Manager", "Finance Specialist"],
    ["General Manager", "GM", "Supervisor", "Manager", "General-Manager"],
    ["Finance Manager", "Finance-Manager"],
    ["Warehouse Manager", "Warehouse-Manager"],
    ["Operations Manager", "Operation Manager", "Operational Specialist", "Operations-Manager", "Vice President Operations"],
    ["President"],
    ["Vice President", "Vice-President"],
    ["Consultant"],
    ["Executive"],
    ["Office Manager", "Officer-Manager"],
    ["IT Manager", "Information Technology Manager", "Information Technology Specialist", "Information Specialist", "IT-Manager"]
]