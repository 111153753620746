import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./LogoutButton";
import LoginButton from "./LoginButton";

const LogInOutButton = () => {
    const { isAuthenticated, isLoading } = useAuth0();


    return !isLoading && (isAuthenticated ? <LogoutButton /> : <LoginButton />);
};

export default LogInOutButton;
